import axios from "axios"

const url = 'http://localhost:3000'

export default {
    login(credentials) {
        return axios
            .post(url + '/users/login', credentials)
            .then(response => response.data)
    },
    register(credentials) {
        return axios
            .post(url + '/users/register', credentials)
            .then(response => response.data)
    }
}