<template>
    <div class="login form-wrapper">
        <h1>Login to <a href="#">playgrounds.co</a></h1>
        <p class="center">New here? <router-link :to="{ name: 'register' }">Create an account!</router-link></p>
        
        <Spacer :size="50" />

        <form @submit.prevent="login">
            <div class="input-group">
                <input 
                    type="text" 
                    v-model="email"
                    name="email" 
                    id="email"
                    inputmode="email"
                    placeholder="elon@musk.com">
                <label for="email">Your email</label>
            </div>
            <div class="input-group">
                <input 
                    type="password" 
                    v-model="password"
                    name="password" 
                    id="password" 
                    placeholder="••••••••••••">
                <label for="email"> Your password</label>
            </div>

            <Spacer :size="20" />

            <button type="submit" class="btn primary full-width">Login</button>

            <div class="notification-wrapper" v-if="message.text">
                <Spacer :size="20" />
                <InlineNotification :success="message.success">
                    {{ message.text }}
                </InlineNotification>
            </div>
        </form>
    </div>
</template>

<script>
import Spacer from "@/components/spacer"
import AuthService from "@/services/AuthService"
import InlineNotification from "@/components/notifications/inline-notification"
import { mapGetters } from "vuex"

export default {
    name: 'login',
    data() {
        return {
            email: '',
            password: '',
            message: {
                text: '',
                success: false
            }
        }
    },
    methods: {
        async login() {
            try {
                const credentials = {
                    email: this.email,
                    password: this.password
                };

                const response = await AuthService.login(credentials);
                this.message = {
                    text: response.message,
                    success: true
                };

                const token = response.userData.token;
                const user = response.userData;

                this.$store.dispatch('login', { token, user });

                this.$router.push({ name: 'overview' });
            } catch(error) {
                this.message = {
                    text: error.message,
                    success: false
                }
            }
        }
    },
    computed: {
        ...mapGetters([ 'currentUser' ])
    },
    updated() {
        if(localStorage.id_token) {
            this.$router.replace('/' + this.currentUser.username)
        }
    },
    created() {
        if(localStorage.id_token) {
            this.$router.replace('/' + this.currentUser.username)
        }
    },
    components: {
        Spacer,
        InlineNotification
    }
}
</script>

<style lang="scss" scoped>
button[type=submit] {
    .username {
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:hover {
        .username {
            opacity: 1;
        }
    }
}
</style>